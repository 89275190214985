import * as imagesLoaded from 'imagesloaded'
import GsapSmoothScroll from './class/GsapSmoothScroll'

import common from './common'
import index from './index'
import usevue from './usevue'

document.addEventListener('DOMContentLoaded', function() {
  imagesLoaded(document.getElementById('app'), ()=>{
    usevue()
    const smoother = new GsapSmoothScroll("#scrollArea", null, 1.6)
    common()
    index()

    setProp()

    function setProp(){
      if(smoother != null){
        document.documentElement.style.setProperty('--screen', `${smoother.getOffsets()}px`)

        const dog = Math.floor(smoother.getOffsets() / 100) * 58
        document.documentElement.style.setProperty('--dog', `${dog}px`)
      }
      requestAnimationFrame(setProp)
    }
  })
});


